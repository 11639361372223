import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavigationBarRoot from "../NavigationComponent/NavigationBarRoot";
import {ResultTable} from "../DataAnalyzerComponent/DataAnalyzer";
import SourceTable from "../SourceTableComponent/SourceTable";
import * as _ from 'lodash';
import * as Auth from "../AuthService";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {Button, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import DataAnnotator from "../DataAnnotatorComponent/DataAnnotator";
import RootExtract from "../ExtractManagerComponent/ExtractManager";
import Modal from "react-bootstrap/Modal";
import RuleDesigner from "../RuleDesignerComponent/RuleDesigner";
import QAManager from "../QAManagerComponent/QAManager";
import api from "../api";
import {properties} from "../properties";
import {AutoComplete, message} from "antd";
import "../index.less";
import AnalysisAgent from '../AnalysisAgent/AnalysisAgent';

class App extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            results: [],
            source: [],
            show: false,
            item: '',
            token: '',
            sample: [],
            itemId: '',
            size: 0,
            approved: 0,
            selected: null,
            batch_size: 0,
            attributes: [],
            currentItem: {
                item: '',
                description: ''
            },
            attr: {
                'attrName': '',
                'class': ''
            },
            markups: [],
            finals: [],
            lastKey: 0,
            batch: '',
            rule:'',
            tagsFilter: null,
        }
    }

    getSourceIds(rows = this.state.source) {
        let source = [];
        for (let i = 0; i < rows.length; i++) {
            source.push(rows[i].item)
        }
        return source;
    }

    findAnnotated() {
        api.get(`/annotations?items=${this.getSourceIds().join(',')}`, Auth.createConfig())
            .then(json => this.setState({markups: json.data.items}))
            .catch(error => console.log(error));
    }

    findFinal() {
        api.get(`/final/annotations?items=${this.getSourceIds().join(',')}`, Auth.createConfig())
            .then(json => this.setState({finals: json.data.items}))
            .catch(error => console.log(error));
    }

    getNumOfApproved() {
        api.get(`/item/approved?batch=${this.state.batch}&class=${this.state.item}`, Auth.createConfig())
            .then(json => this.setState({approved: json.data.num}))
            .catch(error => console.log(error));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.batch !== this.state.batch) {
            this.setState({shouldUpdate: !this.state.shouldUpdate});
            let srch = this.state.batch === new URLSearchParams(window.location.search.replace('%', '&')).get('batch');
            this.showSource(this.state.batch, '', 0, true, srch, null);
        } else if (prevState.item !== this.state.item) {
            this.showSource(this.state.batch, this.state.item, 0, undefined, undefined, null);
        } else if (prevState.key !== 'home' && this.state.key === 'home') {
            this.showSource(this.state.batch, this.state.item, this.state.lastKey);
        } else if(prevState.selected !== this.state.selected && this.state.selected === null){
            this.showSource(this.state.batch, this.state.item, this.state.lastKey);
        }
        if (prevState.source !== this.state.source) {
            this.findAnnotated();
            this.findFinal()
        }
    }

    showSource(batch, item, from, changeBatch = false, search=false, tags=this.state.tagsFilter) {
        let params = {
            batch: batch,
            class: item,
            tags: tags,
            pagination: {"current": from, "pageSize": properties.previewRowsPerPage}
        }
        api.post(`/data/filtered`, params, Auth.createConfig())
            .then(json => {
                this.setState({
                    source: json.data.items,
                    item: item,
                    size: json.data.size,
                    lastKey: from,
                    batch: batch,
                    batch_size: changeBatch ? json.data.size : this.state.batch_size,
                    tagsFilter: tags
                });
                if(search) {
                    let path = window.location.search.replace('%', '&')
                    this.search(new URLSearchParams(path).get('search'), new URLSearchParams(path).get('batch'))
                }
                this.getNumOfApproved();
            })
            .catch(error => {
                console.log(error);
                this.setState({source: []})
            });
    }

    handleSelection(k) {
        this.setState({key: k, currentItem: (k !== 'annotate' && k !== 'qa') ? {} : this.state.currentItem});
    }

    handleAnnotation(item) {
        this.setState({
            currentItem: item,
            key: "annotate"
        });
    }

    handleQA(item) {
        this.setState({
            currentItem: item,
            key: "qa"
        });
    }

    checkSearch(clear = false) {
        let itemIdsString = this.state.itemId.trim();
        let itemIds = itemIdsString.split(' ');
        if (itemIds.length === 1 && !clear) {
            this.search(itemIds[0])
        } else if (itemIds.length > 1 && !clear) {
            if (itemIds.length > 50) {
                message.error('You can only search up to 50 items at a time.');
                return;
            }
            this.search_many(itemIds)
        }else if (clear) {
            this.search(0)
        }
    }
    createRule(token, isGarb, rule = '', _class = '', attr = '') {
        // axios.get(`${properties.apiUrl}/api/token/examples?token=${token}&class=${_class ? _class.className : this.state.item}&batch=${this.state.batch}`, Auth.createConfig())
        //     .then(json => {
        //         if( typeof rule !== 'object'){
        //            rule = {};
        //            rule['attribute'] = attr
        //         }
        //         this.setState({sample: json.data.length > 0 ? json.data : [], token: token, key:'designer', rule: rule,isGarb: isGarb, item: _class ? _class.className : this.state.item})
        //     })
        //     .catch(error => console.log(error))
        if( typeof rule !== 'object'){
            rule = {};
            rule['attribute'] = attr
        }
        this.setState({token: token, key:'designer', rule: rule,isGarb: isGarb, item: rule._class ? rule._class : (_class ? _class.className : this.state.item)})
    }

    openRule(rule_id = '', attr = '', item_id =''){
        if (rule_id) {
            api.get(`/rule/find?id=${rule_id}`, Auth.createConfig())
                .then(json => {
                    let rule = json.data;
                    this.createRule(rule.token, false, rule,'')
                })
                .catch(error => console.log(error));
        } else {
            api.get(`/search?batch=${this.state.batch}&itemId=${item_id}`, Auth.createConfig())
                .then(json => {
                    this.setState({sample: json.data.items, key:'designer', rule: {'attribute': attr}})
                })
                .catch(error => {
                    this.setState({source: []})
                });
        }
    }

    getNext(){
        api.get(`/data/get-next?batch=${this.state.batch}&class=${this.state.item}&item_id=${this.state.currentItem.item}`, Auth.createConfig())
            .then(json => {
                let next_item = json.data.items;
                if (next_item === null) {
                    alert('No more items to annotate. All items have been annotated.');
                } else {
                    this.setState({currentItem: next_item});
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({source: []})
            });
    }


    search(itemId, batch = this.state.batch) {
        if (itemId) {
            api.get(`/search?batch=${batch}&itemId=${itemId}`, Auth.createConfig())
                .then(json => {
                    this.setState({source: json.data.items, size: json.data.size, itemId: '', tagsFilter: null})
                })
                .catch(error => {
                    console.log(error);
                    this.setState({source: []})
                });
        } else {
            this.showSource(this.state.batch, this.state.item, 0, undefined, undefined, null);
        }
    }

    search_many(itemIds, batch = this.state.batch) {
        let post_items = {
            items: itemIds,
            batch: batch
        }
        if (itemIds) {
            api.post(`/search-many`, post_items, Auth.createConfig())
                .then(json => {
                    this.setState({source: json.data.items, size: json.data.size, itemId: ''})
                })
                .catch(error => {
                    console.log(error);
                    this.setState({source: []})
                });
        }
    }

    deleteRule(_class, attr,rule){
        api.get(`/rules/delete?id=${rule._id}`, Auth.createConfig())
            .then(json => {alert('Rule was successfully deleted'); this.setState({shouldUpdate:!this.state.shouldUpdate})})
            .catch(error => console.log(error))
    }

    onChangeTable(page, tags) {
        let lastKey = page - 1;
        this.showSource(this.state.batch, this.state.item, lastKey, undefined, undefined, tags);
    }

    render() {
        return (
            <div className='App'>
                <div className='row' style={{marginRight: 0}}>
                    <div className='col-3'>
                        <NavigationBarRoot
                            shouldUpdate={this.state.shouldUpdate}
                            item={this.state.item}
                            batch={this.state.batch}
                            windowHeight={window.innerHeight}
                            attr={this.state.attr}
                            deleteRule={(_class, attr, rule) => this.deleteRule(_class,attr,rule)}
                            addRule={(_class, attr) => this.createRule('','','',_class, attr)}
                            onRuleSelect={(rule) => this.openRule( rule)}
                            handleAttributes={attr => this.setState({attr: attr, item: attr.class, key: 'extract'})}
                            handleClassChange={item => this.setState({
                                item: item,
                                key: "home",
                                rule: '',
                                sample: [],
                                attr: {'attrName': '', 'class': ''}
                            })}
                            handleBatchChange={(batch, source, size) => {
                                this.setState({
                                    batch: batch,
                                    source: source ? source : this.state.source,
                                    size: size ? size : this.state.size,
                                    batch_size: size ? size : this.state.batch_size,
                                    key: "home",
                                    rule: '',
                                    sample: [],
                                    attr: {'attrName': '', 'class': ''}
                                })
                            }}
                        />

                    </div>
                    <div className='col-9' style={{margin: 0}}>
                        <Tabs id="controlled-tab-example" activeKey={this.state.key}
                              onSelect={k => this.handleSelection(k)}>
                            <Tab eventKey="home" title="Source data">
                                <div style={{margin: 5, float: 'right'}}>
                                    <input placeholder='Search by item id' value={this.state.itemId}
                                           onChange={e => this.setState({itemId: e.target.value})}
                                    />
                                    <Button className="primary-button" onClick={() => this.checkSearch()}>Search</Button>
                                    <Button className="primary-button" onClick={() => this.checkSearch(true)}>Clear</Button>
                                </div>
                                <SourceTable source={this.state.source}
                                             class={this.state.item}
                                             approved={this.state.approved}
                                             markups={this.state.markups}
                                             finals={this.state.finals}
                                             batch={this.state.batch}
                                             batchSize={this.state.batch_size}
                                             handleAnnotation={item => this.handleAnnotation(item)}
                                             openQA={item => this.handleQA(item)}
                                             total={this.state.size}
                                             onChangeTable={(page, tags) => this.onChangeTable(page, tags)}
                                             current={this.state.lastKey + 1}
                                             setSource={source => this.setState({source: source})}
                                             tagsFilter={this.state.tagsFilter}
                                             setTagsFilter={tags => this.setState({tagsFilter: tags})}/>
                            </Tab>
                            {(this.state.token || this.state.rule || this.state.sample.length > 0) && <Tab eventKey="designer" title="Rule Designer">
                                <RuleDesigner token={this.state.token}
                                              sample={this.state.sample}
                                              handleSave={() => this.setState({shouldUpdate: !this.state.shouldUpdate})}
                                              rule={this.state.rule}
                                              batch={this.state.batch}
                                              class={this.state.item}/>
                            </Tab>}
                            {/* {this.state.item && <Tab eventKey="analyze" title="Data Analyzer">
                                <ResultTable values={this.state.results}
                                             batch={this.state.batch}
                                             handleSave={() => this.setState({shouldUpdate: !this.state.shouldUpdate})}
                                             shouldUpdate={this.state.shouldUpdate}
                                             openRule = {(rule) => this.openRule(rule.rule_id)}
                                             createRule={(token, isGarb) => this.createRule(token, isGarb)}
                                             class={this.state.item}/>
                            </Tab>} */}
                            {this.state.currentItem.item &&
                            <Tab eventKey="annotate" title="Annotation Manager">
                                <DataAnnotator currentItem={this.state.currentItem}
                                               onSubmit={() => this.setState({key: "qa"})}/>
                            </Tab>
                            }
                            {this.state.currentItem.item &&
                            <Tab eventKey="qa" title="QA Manager">
                                <QAManager item={this.state.currentItem}
                                           getNext={(submitted) => this.getNext(submitted)}
                                           fromAnnotationKey={this.state.key}
                                />
                            </Tab>
                            }
                            <Tab eventKey="extract" title="Extraction Manager">
                                <RootExtract batch={this.state.batch}
                                                item={this.state.item}
                                                shouldUpdate={this.state.shouldUpdate}
                                                handleUpdate={() => this.setState({shouldUpdate: !this.state.shouldUpdate, key: "home"})}
                                                currentAttr={this.state.attr}
                                                openRule = {(rule_id, attr) => this.openRule(rule_id, attr)}/>
                            </Tab>
                            {this.state.item && <Tab eventKey="analysis-agent" title="Analysis Agent">
                                <AnalysisAgent batch={this.state.batch}
                                                clsName={this.state.item}/>
                            </Tab>}
                            {/* <Tab eventKey="llm-extract" title="LLM Extraction Manager">
                                <LLMExtractManager batch={this.state.batch}
                                                item={this.state.item}
                                                shouldUpdate={this.state.shouldUpdate}
                                                handleUpdate={() => this.setState({shouldUpdate: !this.state.shouldUpdate, key: "home"})}
                                                currentAttr={this.state.attr}
                                                openRule = {(rule_id, attr) => this.openRule(rule_id, attr)}/>
                            </Tab> */}
                            {/*<Tab eventKey="admin" title="Admin Panel">*/}
                            {/*    <AdminComponent batch={this.state.batch}*/}
                            {/*                    shouldUpdate={this.state.shouldUpdate} />*/}
                            {/*</Tab>*/}
                        </Tabs>
                    </div>
                </div>
                {/* <ClassUpdate onClose={() => this.setState({selected: null, shouldUpdate: !this.state.shouldUpdate})} item={this.state.selected}/> */}
            </div>
        );
    }
}


class ClassUpdate extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            name: '',
            triedToSubmit: false,
            classes: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.item !== this.props.item && this.props.item !== null){
            this.setState({name: this.props.item.class_name})
            this.getClassesList()
        }
    }

    checkRequired(value) {
        return (value.length === 0 && this.state.triedToSubmit) ? 'error' : null;
    }

    handleSubmit(e){
        e.preventDefault();
        if(this.state.classes && this.state.name.length > 0) {
            let find = this.state.classes.find(_class => _class.value === this.state.name)
            if (!find) {
                alert('Couldn\'t find the class in the class library. Please check for typos')
            }
            else {
                api.get(`/item/update_class?name=${this.state.name}&item=${this.props.item.item}`, {})
                    .then((json) => {
                        this.props.onClose()
                    })
                    .catch(error => alert(error.response.data))
                    .finally(() => this.setState({
                        triedToSubmit: false
                    }));
            }
        } else {
            alert('Field is empty!')
        }
    }

    getClassesList = () => {
        api.get('/classes/full')
            .then(json => {
                this.setState({classes: json.data})
            })
    }

    render() {
        if (this.props.item !== null) {

            const setValue = data => {
                this.setState({name: data})
            }

            return (
                <Modal show onHide={this.props.onClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update item {this.props.item.item}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <form onSubmit={e => this.handleSubmit(e)}>
                                <AutoComplete
                                    style={{ width: 300 }}
                                    options={this.state.classes}
                                    value={this.state.name}
                                    onChange={setValue}
                                    placeholder="Start typing the class"
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                />
                                <Button className="primary-button" style={{float: 'right'}} type="submit" bsStyle="primary"
                                        loading={this.state.submitInProgress} onClick={e => this.handleSubmit(e)}>Update class</Button>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            );
        } else {
            return null;
        }
    }
}

export default App;
