import React, {useEffect, useState} from "react";
import api from "../../api";
import * as Auth from "../../AuthService";
import {Table, Button, Tag, Dropdown, Space, Menu} from "antd";
import "./ExamplesTableAttributeAnalyzer.css";
import {properties} from "../../properties";

function EntityLinksDropdown({material, children}) {
    const items = <Menu>
        <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href={`${properties.analyticsLink}/projects/6295c7173ae8947d89af2c73/min-max-material-analysis?material=${material}`}>
                Workbench
            </a>
        </Menu.Item>
        <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href={`${properties.ihLink}/data?operator=Client&material=${material}`}>
                Inventory
            </a>
        </Menu.Item>
    </Menu>

    return (
        <Dropdown overlay={items}>
            {children}
        </Dropdown>
    );
}

export default function ExamplesTableAttributeAnalyzer({ dataSource, page, setPage }) {

    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            width: 50,
            render: (item) => <EntityLinksDropdown material={item} children={<p className="example-table-item-link">{item}</p>}/>,
        },
        {
            title: 'Class',
            dataIndex: 'class',
            width: 50,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            render: (description) => <span dangerouslySetInnerHTML={{ __html: description }} />,
            width: 500,
        },
        {
            title: 'Value',
            dataIndex: 'value',
            render: (value) => <p style={{margin: 5, whiteSpace: 'break-spaces', wordBreak: 'break-word'}}>{value}</p>,
            width: 150,
        },
        {
            title: 'Source',
            dataIndex: 'source',
            render: (source) => <Tag color="blue">{source.toUpperCase()}</Tag>,
            width: 100,
        },
    ];

    return (
        <Table
            tableLayout="fixed"
            className="analysis-table-examples primary-table"
            columns={columns}
            pagination={{
                current: page,
                onChange: (page) => setPage(page),
                pageSize: 10,
                showSizeChanger: false,
            }}
            dataSource={dataSource}
        />
    )
}