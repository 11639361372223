import React, {useEffect, useState} from "react";
import api from "../../api";
import * as Auth from "../../AuthService";
import {Table, Button, Space, Dropdown, Menu, Slider} from "antd";
import FileDownload from "js-file-download";
import ExamplesTableAttributeAnalyzer from "./ExamplesTableAttributeAnalyzer";
import * as _ from "lodash";
import "./TableAttributeAnalyzer.css";
import { properties } from "../../properties";


function DropdownExamplesVariants({getExamples, getDownload}){
    const [count, setCount] = useState(50);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const onChange = (value) => {
        setCount(value);
    };

    const handleMenuClick = (e) => {
        if (e.key !== 'slider') {
          setOpen(false);
        }
    }

    const menu = (
        <Menu onClick={handleMenuClick} style={{width: 200}}>
            <Menu.Item key="sample" onClick={() => getExamples(count, true, (state) => setLoading(state))}>Random {count} examples</Menu.Item>
            <Menu.Item key="first" onClick={() => getExamples(count, false, (state) => setLoading(state))}>First {count} examples</Menu.Item>
            <Menu.Item key="download" onClick={() => getDownload((state) => setLoading(state))}>Download all examples</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="slider">
                Count of examples: <Slider min={10} max={100} step={5} defaultValue={count} onChange={onChange} />
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} open={open} onOpenChange={setOpen} trigger={['click']}>
          <Button loading={loading}>Examples</Button>
        </Dropdown>
    );
}

export default function TableAttributeAnalyzer({ dataSource }) {
    const [examples, setExamples] = useState([]);
    const [examplesPage, setExamplesPage] = useState(1);


    const getExamples = (action, count = 50, sample = false, setLoadingGetExamples) => {
        setLoadingGetExamples(true);
        api.get(`/statistics/attribute/examples?value=${action.value}&source=${action.source}&class=${action.class}&attribute=${action.attribute}&batch=${action.batch}&count=${count}&sample=${sample}`, Auth.createConfig())
            .then(json => {
                const _examples = json.data.examples.map(example => {
                    let description = example.description;
                    let _description = "";
                    if (example.positions && example.positions.length > 0) {
                        const positions = _.sortBy(example.positions, 'from');
                        let lastIndex = 0;
                        positions.map((position) => {
                            _description += description.substring(lastIndex, position.from);
                            _description += `<span style='background-color: ${properties.colors.yellow}';>${description.substr(position.from, position.length)}</span>`;
                            lastIndex = position.from + position.length;
                        });
                        _description += description.substr(lastIndex);
                    }
                    return {
                        item: example.item,
                        class: example.class,
                        description: _description.length > 0 ? _description : description,
                        value: example.value,
                        tokens: example.tokens,
                        source: example.source,
                    };
                });
                setExamples(_examples);
                setExamplesPage(1);
            })
            .catch(error => {
                console.log(error); 
                alert("Sorry, something went wrong.");
            })
            .finally(() => setLoadingGetExamples(false));
    }

    const downloadExamples = (action, setLoadingDownloadExamples) => {
        setLoadingDownloadExamples(true);
        const config = Auth.createConfig();
        config['responseType'] = 'blob';
        api.get(`/statistics/attribute/examples/download?value=${action.value}&source=${action.source}&class=${action.class}&attribute=${action.attribute}&batch=${action.batch}`, config)
            .then(response => {
                const header = response.headers['content-disposition'];
                const filename = /filename=(.*)/.exec(header)[1];
                FileDownload(response.data, filename);
            })
            .catch(error => {
                console.log(error); 
                alert("Sorry, something went wrong.");
            })
            .finally(() => setLoadingDownloadExamples(false));
    }

    const columns = [
        {
            title: 'Value',
            dataIndex: 'value',
            width: 350,
            sorter: (a, b) => a.value.localeCompare(b.value),
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            render: (frequency) => frequency[1] > 1 ? `${frequency[0]} (${frequency[1]}%)` : `${frequency[0]} (<1%)`,
            width: 50,
            defaultSortOrder: "descend",
            sorter: (a, b) => a.frequency[1] - b.frequency[1],
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            render: (action) => <DropdownExamplesVariants 
                                    getExamples={(count, sample, setLoadingGetExamples) => getExamples(action, count, sample, setLoadingGetExamples)} 
                                    getDownload={(setLoadingDownloadExamples) => downloadExamples(action, setLoadingDownloadExamples)}
                                />,
            width: 50,
        },
    ];

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <Table
                showSorterTooltip={false}
                tableLayout="fixed"
                className="analysis-table primary-table"
                columns={columns}
                dataSource={dataSource}
                showSizeChanger={false}
                pagination={{
                    pageSize: 10,
                    showSizeChanger: false,
                }}
            />
            {examples.length > 0 && <ExamplesTableAttributeAnalyzer 
                                            dataSource={examples} 
                                            page={examplesPage} 
                                            setPage={(page) => setExamplesPage(page)} />
            }
        </Space>
    )
}