import React, {useEffect, useState} from "react";
import api from "../../api";
import * as Auth from "../../AuthService";
import * as _ from "lodash";
import {Button, Select, Card, Space} from "antd";
import TableAttributeAnalyzer from "./TableAttributeAnalyzer";
import "./AttributeAnalyzer.css";

export default function AttributeAnalyser({ batch, clsName }) {
    const [attributes, setAttributes] = useState([]);
    const [attributeToAnalyze, setAttributeToAnalyze] = useState("");
    const [attributeStatistics, setAttributeStatistics] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sizeOfClass, setSizeOfClass] = useState(0);
    const [openAnalysisTable, setOpenAnalysisTable] = useState(false);

    const sourceOptions = [
        {
            value: 'annotations',
            label: 'Annotations',
        },
        {
            value: 'rule-extractions',
            label: 'Regex Extractions',
        },
        {
            value: 'llm-extractions',
            label: 'LLM Extractions',
        },
        {
            value: 'finals',
            label: 'Finals',
        },
    ]
    const [source, setSource] = useState(sourceOptions[0].value);


    useEffect(() => {
        api.get(`/attributes?class=${clsName}`, Auth.createConfig())
            .then(json => {
                setAttributes(json.data.items);
                setAttributeToAnalyze(json.data.items[0].attributeName);
                setOpenAnalysisTable(false);
                setSource(sourceOptions[0].value);
            })
            .catch(error => console.log(error));
    }, [clsName]);

    const handleSearch = () => {
        setLoading(true);
        api.get(`/statistics/attribute?batch=${batch}&class=${clsName}&source=${source}&attribute=${attributeToAnalyze}`, Auth.createConfig())
            .then((response) => {
                let statistics = response.data.statistics;
                let size = response.data.size;
                let data = statistics.map((stat) => ({
                    value: stat.value,
                    frequency: [stat.frequency, _.round((stat.frequency / size).valueOf().toPrecision(4) * 100, 2)],
                    action: {
                        batch: batch,
                        class: clsName,
                        attribute: attributeToAnalyze,
                        source: source,
                        value: stat.value,
                    }
                }));
                setAttributeStatistics(data);
                setSizeOfClass(size);
                setOpenAnalysisTable(true);
            })
            .catch((error) => {
                console.log(error);
                alert("Sorry, something went wrong.");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Card title={<p style={{margin: 0}}>Analysis for class: <b>{clsName}</b> (batch: <b>{batch}</b>)</p>} className="analysis-card">
            <Space direction="vertical" style={{width: "100%"}}>
                <Space>
                    Source:
                    <Select 
                        placeholder="Select Source"
                        value={source}
                        style={{width: 200, marginRight: 20}}
                        options={sourceOptions}
                        onChange={(value) => {setSource(value); setOpenAnalysisTable(false)}}
                    />
                    Attribute:
                    <Select 
                        placeholder="Select Attribute"
                        style={{width: 200}}
                        value={attributeToAnalyze}
                        options={attributes.map((attribute) => ({
                            value: attribute.attributeName,
                            label: attribute.attributeName,
                        }))}
                        onChange={(value) => {setAttributeToAnalyze(value); setOpenAnalysisTable(false)}}
                    />
                </Space>
                <div style={{display: "flex", justifyContent: "flex-end", marginTop: -40}}>
                    {sizeOfClass > 0 && <p style={{marginRight: 20, marginTop: 5}}> <b>Total items: {sizeOfClass}</b></p>}
                    <Button
                        style={{marginLeft: 5}}
                        disabled={!source || !attributeToAnalyze || loading}
                        onClick={handleSearch}
                        loading={loading}
                    >
                        Search
                    </Button>
                </div>
                {openAnalysisTable && <TableAttributeAnalyzer style={{marginTop: 20}} dataSource={attributeStatistics} />}
            </Space>
        </Card>
        
    )
}